<template>
    <div class="appstore-intro">
        <h2>通过 App Store 安装 零熵</h2>
        <div class="appstore-intro-tips">
            💡 如果您拥有美国/香港等地区的Apple ID，请直接登录App Store下载
        </div>
        <h3>第一步 准备一个美国/香港地区Apple ID</h3>
        <p>如果您没有美国/香港地区的Apple ID，可以尝试通过以下方式获取：</p>
        <p><strong>
                方式一：淘宝购买（方便快捷）
                <a href="/buy" class="">查看如何购买</a></strong></p>
        <h3>第二步 在App Store 切换 Apple ID</h3>
        <p>1、打开 App Store，点击右上角的头像</p> <img src="">
        <p>2、点击退出登录后，输入非大陆地区的 Apple ID 和密码重新登录 </p> <img src=""> <img src="">
        <p class="appstore-intro-help">如果提示“无法登录”，有可能是您近期切换登录频率太高，可以更换 ID 或者几个小时之后重试。如果 ID
            被锁定，则需要进行验证或更换ID再试。</p>
        <h3>第三步 安装App</h3>
        <p>1、登录成功后，会自动跳转至 ID 对应地区的 App Store，在 App Store 中搜索“零熵”，点击“GET”按钮，开始安装App</p>
        <img src="">
        <p>2、回到桌面查看即可</p> <img src="">
        <div class="appstore-btn"><a href="/" target="_blank">去App
                Store安装</a></div>
    </div>
</template>

<style>
.appstore-intro {
    color: #37352f;
    margin: 0 auto;
    max-width: 700px;
    padding: 15px 15px 100px;
    width: calc(100% - 30px);

    p {
        margin: 10px 0;
        text-indent: 1rem;
    }

    img {
        display: block;
        margin: 10px auto;
        max-width: 60%;
    }
}

.appstore-intro-tips {
    background: #f1f1ef;
    border-radius: 2px;
    padding: 10px;
}

.appstore-intro-help {
    color: #666;
}

.appstore-btn {
    background: #000;
    border: 0;
    bottom: 0;
    height: 50px;
    left: 0;
    outline: none;
    position: fixed;
    right: 0;
    text-align: center;

    a {
        color: #fff;
        font-size: 140%;
        line-height: 50px;
    }
}
</style>