<template>
  <div class="draft-container">
    <p style="text-align:center;"><strong><span style="line-height:3;font-size:1.1946666666666668rem">0xSeek Privacy
          Policy</span></strong></p>
    <p><span style=" font-size:0.6826666666666666rem"><span style="line-height:2">Update Date: February 6,
          2024</span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Effective Date: March 10,
          2024</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">"0xSeek" and related services refer to
          the client application, 0xseek.com website, and browser plugins legally owned and operated by Beijing
          Dakexiang Technology Co., Ltd. (hereinafter referred to as "we"). As the operator of 0xSeek, we fully
          understand the importance of your personal information to you. Your trust is very important to us. We will
          protect your personal information and privacy security in accordance with the provisions of laws and
          regulations and follow the industry's best practices. We have formulated this "Privacy Policy" and specially
          remind you: We hope you will carefully read and understand this privacy policy before using 0xSeek and
          related services, so you can make appropriate choices.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>This privacy policy will help
            you understand:</strong></span></span> </p>
    <ul>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2">We will collect and use your
            information in accordance with this privacy policy, but we will not collect personal information in a
            bundled manner simply because you agree to this privacy policy.</span></span></li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2">When you use or activate related
            features or services, we will collect and use relevant information as necessary to realize the function or
            service. Unless it is necessary information necessary for basic business functions or required by laws and
            regulations, you can refuse to provide it without affecting other functions or services. We will explain
            item by item in the privacy policy which are necessary information.
          </span></span></li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>If you have not logged in to
              your account, we will use the corresponding identifier information of the device to ensure the basic
              function of information push. If you have logged in to your account, we will implement information push
              based on account information.</strong></span></span>
      </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2">This privacy policy applies to your
            access and use of our products and services through the 0xSeek application and 0xSeek website（<a
              href="https://www.0xseek.com" data-href="https://www.0xseek.com" target="_blank"
              rel="noopener noreferrer">www.0xseek.com</a>） If the services or products you use from us or our
            affiliates have their separate privacy policies, the corresponding services or products apply to the
            corresponding privacy policy.<strong> if no separate privacy policy is set up, this policy also applies to
              that part of the product or service.</strong></span></span>
      </li>
    </ul>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">The following will help you understand
          in detail how we collect, use, store, transmit, share, transfer (if applicable), and protect personal
          information; help you understand the ways to access, copy, delete, correct, and withdraw authorization of
          personal information. This policy is closely related to your use of our services. We recommend that you
          carefully read and understand the entire content of this policy and make choices you deem appropriate. Among
          them,
          <u><strong>important content regarding your personal information rights has been highlighted in bold for your
              special attention.</strong></u></span></span> </p>
    <ol>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>How we collect and use
                personal information</strong></u></span></span> </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>How We Use Cookies and
                Similar Technologies</strong></u></span></span> </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>Data Use Involving
                Partners
                and Transfer, Public Disclosure of Personal Information</strong></u></span></span> </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>How We Store Personal
                Information</strong></u></span></span> </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>How We Protect the
                Security
                of Personal Information</strong></u></span></span> </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>Personalized
                Recommendations</strong></u></span></span> </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>Managing Your Personal
                Information</strong></u></span></span> </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>Terms for
                Minors</strong></u></span></span> </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>Revision and Notification
                of
                the Privacy Policy</strong></u></span></span> </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>Contact
                Us</strong></u></span></span> </li>
    </ol>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.How we collect and use
            personal information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">We will collect information that you
          actively provide while using the service, as well as information generated during the use of features or
          receipt of services through automated means, in the following ways: </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.1
            Registration, Login</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.
        </span></span>When you register or log in to 0x and related services, you can create an account with
      your email/phone number, and you can complete relevant online identity recognition information (avatar, nickname,
      password). Collecting this information helps you complete the registration. The phone number is necessary
      information to fulfill the requirements of the national laws and regulations regarding network real-name
      registration. If you do not provide a phone number for registration/login, we may not be able to provide you with
      information publishing, commenting, and other functions. After you register and log in to your account, you can
      fill in personal information (position, company, profile, blog address) to complete your information. We will
      collect the personal information you actively fill in to display to other users and to provide you with
      recommended content.
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.
          Based on our cooperation with communication operators, when you use the "One-click Login" function of 0xSeek, with your consent, we will process your phone number with the operator to provide you with a fast
          login service. If you refuse the above processing, you will not be able to register and log in to 0xSeek
          using the "One-click Login" method, but it does not affect your registration and login in other ways, nor does
          it affect the normal use of other functions.

        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">c.
          You can also log in and use 0xSeek using a third-party account. You will authorize us to obtain your
          publicly registered information on third-party platforms (avatar, nickname, and other information you
          authorize) for binding with the 0xSeek account, allowing you to log in and use the product and related
          services directly.</span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.2 Content
            Browsing</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">0xSeek may recommend more
          interesting information to you based on the tags, topics, and users you subscribe to within 0xSeek. To
          achieve this function, we may collect necessary log information. We will not use data from third parties to
          achieve the above function.

        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.3
            Publishing and Interaction</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.3.1
            Information Publishing</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.
          When you publish content, comments, questions, or answers, we will collect the information you publish and
          display your nickname, avatar, and published content.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.
          When you use the upload picture or change avatar function,we will request your authorization
          for<u><strong>camera and photo album access. If you refuse to grant permission, you will not be able to use
              this function, but it will not affect the normal use of other functions in 0xSeek.</strong></u></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">c.
          Information collected about you due to your use of our products or services, for example, other users’ posts
          may contain some information about you (e.g., involving you in comments, messages, posts).</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">d.
          If you wish to use the basic features provided by 0xSeek, you can learn about and use the basic function
          mode. In the basic function mode, 0xSeek will only collect your personal information necessary for
          ensuring the software and services' secure operation, in accordance with legal and regulatory requirements,
          without collecting your other personal information. To ensure the minimal necessary collection of your
          personal information, you can use our products or services in browsing mode (i.e., not logged in), and when
          using the "basic function mode," you can only use basic service functions such as topic discussion,
          information sharing, and interaction.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.3.2
            Interactive Communication</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">You actively follow accounts or topics
          you are interested in and interact with them, browse, comment, collect, like, or share content, we will
          collect the accounts you follow and display the content published by the accounts you follow.

        </span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.4
            Search</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">When you use the "0xSeek" search
          service, we will collect your search keywords, log records. To provide efficient search services, some of the
          above information will be temporarily stored on your local storage device and may show you search result
          content and search history.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.5
            Application to Become a 0xSeek Course Author</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">When you apply to become a 0xSeek
          course author, we will collect your<u><strong>real name, contact information</strong></u>(email, WeChat, or
          phone number)、current company and position, author's profile, blog, and technical media information. If you do
          not fill in the aforementioned information, you may not become a 0xSeek course author, but it will not
          affect your use of other functions in 0xSeek.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.6 Real-name
            Authentication</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">When you apply for withdrawal or use
          other functions or services that require real-name authentication, to meet the requirements of laws and
          regulations, ensure your or other users' safety, or maintain the safety and stability of the function/service,
          <strong>you need to provide real identity information (real name, identity document number, which may vary
            according to the actual situation) to complete real-name authentication.</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">During the real-name authentication
          process, your real identity information may need to be verified by third-party authentication institutions.
          This information will be used for the purposes of completing real-name authentication, filing personal income
          tax on your behalf, or other purposes stipulated by laws and regulations. It will not be used for other
          purposes without your explicit authorization. You can refuse to provide it, but you may not be able to use the
          functions or related services that require real-name authentication. Not being able to use these functions or
          services will not affect your use of other functions and services.</span></span>
    </p>
    <p><strong><span style="font-size:0.6826666666666666rem"><span style="line-height:2">1.7
            Other</span></span>Functions</strong>
    </p>
    <p>
      When you share or receive shared information, etc., we need to access your clipboard to read the links contained
      therein to implement sharing and other functions or services. To analyze application additions, activations,
      retention, performance, and other statistical indicators, we may access the clipboard for attribution analysis of
      related statistical information. Please rest assured that we will not collect your private information.
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.8
            Operational and Security Operation/Guarantee</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.8.1
            Operational and Security Guarantee Functions</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">We are committed to providing you with
          a safe, trustworthy product and usage environment. Providing high-quality and reliable services is our core
          goal. To maintain the normal operation of our services, protect you, other users, or the public's legal
          interests from harm, we will collect information necessary for maintaining the product or service's safe
          and<u><strong>stable operation.</strong></u>。
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.8.2
            Device Information and Log Information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a. </span></span><span
        style="background-color:#ffffff">To ensure the software and services' safe operation and operational quality and
        efficiency, we will collect your hardware model, operating system version number, device identifiers (Android
        such as IMEI/MEID, AndroidID, OAID, IMSI, SIM card information (such as ICCID), hardware serial number (SN), iOS
        such as IDFV, IDFA; different identifiers have different validity periods, whether they can be reset by the
        user, and acquisition methods), network device hardware address (device MAC address), IP address, WLAN access
        points (such as SSID, BSSID), Bluetooth, software version number, network access mode, type, status, network
        quality data, operation, service log, device sensor data（</span>such as accelerometer, gyroscope sensors, etc.）.
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b. To prevent malicious programs and
          ensure operational safety, we will collect installed application information or running process information,
          overall application running, usage frequency, application crash situations, overall installation usage,
          performance data, and application sources.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">c.
          We may use your account information, device information, service log information, and information shared by
          our affiliates, partners authorized by you, or legally shareable to assess account security, perform identity
          verification, detect, and prevent security incidents.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.9
            Changes to the Purposes of Collecting and Using Personal Information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Please understand that with the
          development of our business, the functions of 0xSeek and the services provided may be adjusted.
          Principally, when new functions or services are related to those we currently provide, the collection and use
          of personal information will be directly or reasonably related to the original processing purposes. In
          scenarios where the collection and use of your personal information are not directly or reasonably related to
          the original processing purposes, we will again inform you separately of the collection, use of your personal
          information, and obtain your consent according to legal, regulatory requirements, and national standards
          through page prompts, interactive processes, agreement confirmation, etc.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>1.10
            Exceptions to Obtaining Authorized Consent</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Please understand that under the
          following circumstances, according to laws, regulations, and national standards, we can collect and use your
          personal information without obtaining your prior consent:</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a. Related to our fulfillment of
          obligations under laws and regulations;</span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b. Directly related to national
          security or national defense security;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">c. Directly related to public safety,
          public health, or major public interests;
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">d. Directly related to criminal
          investigations, prosecutions, trials, and judgment enforcement;
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">e.
          For the significant legal rights of you or other individuals, such as life and property, but it is difficult
          to obtain consent;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">f. Personal information that you
          disclose to the public on your own;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">g.
          Collecting personal information from legally publicly disclosed information, such as legal news reports,
          government information disclosure, and other channels;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">h. Necessary for signing a contract or
          fulfilling a contract at the request of the personal information subject;
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">i.
          Necessary for maintaining the safe and stable operation of the provided software and services, such as
          detecting and handling failures of software and services;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">j. Necessary for legitimate news
          reporting;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">k.
          Necessary for academic research institutions to conduct statistical or academic research in the public
          interest, and the provided academic research or descriptive results de-identify personal information contained
          in the results;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">l. Other circumstances stipulated by
          laws and regulations.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>Special notice: if the
              information cannot individually or in combination with other information identify your personal identity,
              it does not constitute your personal information in the legal sense; when your information can identify
              your personal identity individually or in combination with other information, or when we use data that
              cannot establish a link with any specific personal information in combination with other personal
              information, these information during the combination use period will be treated and protected as your
              personal information in accordance with this privacy policy.</strong></u></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>2.How We Use Cookies and
            Similar Technologies</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Cookies and similar technologies are
          widely used on the Internet. When you use 0xSeek and related services, we may send one or more cookies
          or anonymous identifiers to your device to collect and store information when you visit or use our product. We
          promise not to use cookies for any purpose other than those described in this privacy policy. We mainly use
          cookies and similar technologies to achieve the following functions or services:</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>2.1
            Ensuring the Secure, Efficient Operation of Products and Services</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">We may set authentication and security
          cookies or anonymous identifiers to ensure your secure login to the services or whether you encounter
          unauthorized use, fraud, and other illegal activities. These technologies also help us improve service
          efficiency, enhance login, and response speed.

        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>2.2
            Helping You Obtain an Easier Access Experience</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Using such technologies can help you
          save the step and process of repeatedly filling in personal information or entering search content (for
          example, recording search, form filling).
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>2.3
            Recommending, Displaying, Pushing Content or Accounts You May Be Interested In
          </strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a We may use cookies and similar
          technologies to understand your preferences and usage habits, conduct data analysis to improve product
          services, recommend information or features you may be interested in.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.In the sharing page of 0xSeek,
          we may use cookies to record browsing activities for recommending information and troubleshooting crashes,
          delays, and exploring better service methods.</span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>2.4
            Clearing Cookies</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Most web browsers provide the
          functionality for users to clear browser cache data. You can clear corresponding data operations through the
          browser's settings function. If you perform a clear, modifications may affect your use of services or
          corresponding functions that rely on cookies provided by the company.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.Data Use Involving Partners
            and Transfer, Public Disclosure of Personal Information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.1 Data Use Involving Partners
          </strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.1.1
            Principles</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>a.Legality
            Principle:</strong>Data use activities involving partners during cooperation must have a legal purpose,
          comply with legal bases. If partners' use of information no longer complies with the legality principle, they
          should not use your personal information, or only use it after obtaining the corresponding legal
          basis.<strong></strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>b. Legitimacy and Minimal
            Necessity Principle:</strong>Data use must have a legitimate purpose and be limited to what is necessary to
          achieve the purpose.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>c.Security and Prudence
            Principle:</strong>We will prudently assess the purpose of partners' data use, comprehensively evaluate
          their security capabilities, and require them to
          follow the cooperation legal agreement. We will conduct strict security monitoring of the software development
          kits (SDKs) and application programming interfaces (APIs) that partners use to obtain information, to protect
          data security.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.1.2
            Entrusted Processing</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">For scenarios involving the entrusted
          processing of personal information, we will sign relevant processing agreements with entrusted partners
          according to legal requirements and supervise their activities related to the use of personal
          information.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.1.3
            Joint Processing</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">For scenarios involving joint
          processing of personal information, we will sign relevant agreements with partners according to legal
          requirements, specifying the rights and obligations of each party, to ensure compliance with legal provisions
          and data security during the use of relevant personal information.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.1.4
            Scope of Partners</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">If specific functions and scenarios
          involve services provided by our affiliates or third parties, the scope of partners includes our affiliates
          and third parties.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.1.5
            Data Use for Function or Service Realization</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.
          When you use functions in 0xSeek provided by our partners, or when software service providers, smart
          device providers, and system service providers jointly offer services with us, we may share the necessary
          information with them to achieve business purposes, conduct comprehensive statistics, and create indirect user
          profiles to recommend, display, or push information you may be interested in or specific functions, services,
          or commercial advertisements more suited to you.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.
          Based on our cooperation with telecommunications operators, when you use the "One-click Login" feature in 0xSeek, with your consent, we will process your phone number with the operator to provide you with quick
          login services. Refusing such processing will prevent you from using the "One-click Login" to register and log
          in to 0xSeek, but it will not affect your registration and login through other methods, nor the normal
          use of other functions.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">c.
          Wallet Function: The services related to the wallet function are provided by our affiliates and partners. When
          you use the wallet-related functions, our affiliates and partners may collect the necessary information within
          the required limits.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">d.
          Payment
          Function:The payment function is provided by third-party payment institutions in cooperation with us. These
          third-party payment institutions may need to collect your name, type and number of your bank card, validity
          period, and phone number. The bank card number, validity period, and phone number are sensitive personal
          information, which are necessary for the payment function. Refusing to provide them will prevent you from
          using this function, but will not affect the normal use of other functions.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">e.
          Customer Service: To promptly process your complaints, suggestions, and other requests, customer service
          providers need to use your account and information related to the event you are complaining about to
          understand, process, and respond to related issues in a timely manner.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.1.6
            Data Use for Advertising Business</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.Advertising Push and Placement:
          Partners providing push/promotion and advertising placement or the relevant services use de-identified or
          anonymized device, network, channel information, and tag information for advertising placement, optimization,
          and increasing advertising reach effectiveness. Partners working with advertisers may need to use the
          aforementioned information to optimize advertising effectiveness. We require them to follow the principles of
          legality, legitimacy, and necessity, ensuring users' legal rights are not violated.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.Advertising Statistics and Analysis:
          Partners providing push/promotion, advertisement placement, and the respective analysis services may need to
          use your device, network, advertisement clicks, browsing, display, and conversion data for analysis,
          measurement, and improvement of advertisement and related services' effectiveness.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">c.Use of Information by Advertising
          Partners: Advertising partners may combine the aforementioned information with other legally obtained data to
          optimize advertisement placement effectiveness. We require them to ensure the use of information follows the
          principles of legality, legitimacy, and necessity, protecting users' legal rights.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">d. Advertising Lead Information: When
          you voluntarily fill in your contact information, address, and other information on the advertisement display
          page provided by advertisers or their commissioned service providers, these information may be directly
          collected and used by advertisers.
        </span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.1.7
            Information Sharing for Security and Analytical Statistics</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.Ensuring Usage Safety: We highly
          value account and service security. To protect the security of your and other users' accounts and property,
          and ensure our and users' legitimate rights and interests are not illegally infringed, we and our affiliates
          or service providers may share necessary device, account, and log information.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.Analyzing Product Usage: To analyze
          the usage of our services, improve user experience, our partners may need to use information related to
          product usage (crashes, app exits), device identifier information, and overall installation and usage
          information.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.1.8
            Assisting You in Participating in Marketing Promotions
          </strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">When you choose to participate in
          marketing activities organized by us and our partners, to ensure you receive consistent services in joint
          activities or delegate partners to fulfill rewards timely, we and our partners may need to use your name,
          mailing address, contact information, bank account, and other information (varies by activity; specific
          information will be according to the activity rules). Refusing such use may affect your participation in
          relevant activities, but it will not affect the use of other functions.
        </span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.2
            Transfer</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">With the continuous development of our
          business, we and our partners might undergo mergers, acquisitions, asset transfers, and your personal
          information may be transferred as a result. When such changes occur, we will prompt you in accordance with
          legal requirements and ensure that the recipient of your personal information continues to protect your
          information at standards not lower than those outlined in this privacy policy. If the recipient changes the
          originally stated purposes and methods for processing personal information, we will require the recipient to
          re-obtain your consent.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.3 Public
            Disclosure</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.We will not publicly disclose your
          information unless it is in accordance with the law or with your consent. When publicly disclosing your
          personal information, we will use security protection measures that comply with industry
          standards.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b. when publicly announcing penalties
          for accounts involved in violations or fraudulent activities, we may disclose relevant account information
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">c. When publishing lists such as author
          contribution rankings and other official 0xSeek selections, we may disclose information publicly
          available on your personal homepage.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>3.4
            Exemptions for Consent in Providing and Publicly Disclosing Personal Information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Under the following circumstances,
          according to laws, regulations, and national standards, we can provide or publicly disclose your personal
          information without obtaining your authorization or consent:
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.
          Necessary for contract signing, performance, or following legal and regulatory human resource management
          requirements;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b. Necessary for fulfilling legal
          duties or obligations;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">c. Directly related to national
          security or defense;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">d. Directly related to criminal
          investigation, prosecution, trial, and judgment enforcement;
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">e.
          Necessary for responding to public health emergencies or protecting the significant legal rights of
          individuals in emergency situations;</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">f. Necessary for legal news reporting
          or public opinion supervision within a reasonable scope;
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">g. Dealing with your personal
          information that you have made public;
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">h. Collecting personal information from
          legally publicly disclosed information;</span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">i. Other circumstances as stipulated by
          laws and regulations.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>4.How We Store Personal
            Information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>4.1 Storage
            Location</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Your information will be stored on
          secure servers, accessible only by authorized staff. Depending on the service's requirements, your personal
          information may be stored on servers outside your country/region. We will take all reasonably necessary
          measures to ensure the security of your data and comply with this privacy policy and applicable data
          protection laws.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>4.2 Storage
            Duration</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">We only retain your personal
          information for the period necessary to provide 0xSeek and its services. For example:
        </span></span></p>
    <ul>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Phone number: When you use a phone
            number to register and log in, we need to continuously retain your phone number to provide services, address
            inquiries and complaints, and ensure your account and system security.</span></span>
      </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Email: When you use email to
            register and log in, we need to continuously retain your email for the same purposes as above.</span></span>
      </li>
      <li><span style="font-size:0.6826666666666666rem"><span style="line-height:2">The information you publish,
            comments, likes, and related information will be retained as long as you do not withdraw, delete, or cancel
            your account, allowing us to continuously provide business functions.</span></span></li>
    </ul>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">If you cancel your account, proactively
          delete personal information, or after the necessary period has expired, we will delete or anonymize your
          personal information unless otherwise required by law.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>5.How We Protect the Security
            of Personal Information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">5.1
          We attach great importance to the security of your personal information and will take reasonable security
          measures (including technical and management aspects) to protect your personal information from unauthorized
          use or unauthorized access, disclosure, use, modification, damage, loss, or leakage.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">5.2
          We will use encryption technology, anonymization, and other reasonable and feasible measures to protect your
          personal information and employ security protection mechanisms to prevent your personal information from
          malicious attacks.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">5.3
          We will establish specialized security departments, security management systems, and data security processes
          to ensure the security of your personal information. We adopt strict data use and access systems, ensuring
          that only authorized personnel can access your personal information, and conduct security audits of data and
          technology when appropriate.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>5.4
              Despite taking the above reasonable and effective measures and complying with the standards required by
              relevant laws and regulations, please understand that due to technical limitations and various malicious
              methods, it is impossible to always guarantee 100% security of information on the internet. We will do our
              best to ensure the security of the personal information you provide to us.</strong></u></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>5.5
              Please be aware that the systems and communication networks you use to access our services may experience
              problems due to factors beyond our control. Therefore, we strongly recommend that you take active measures
              to protect the security of your personal information, including using complex passwords, changing
              passwords regularly, and not disclosing your account password and related personal information to
              others.</strong></u></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">5.6
          We will develop emergency response plans and immediately activate emergency plans in the event of a user
          information security incident, striving to prevent the impact and consequences of such security events from
          expanding. Should a user information security event (leakage, loss) occur, we will inform you of the basic
          situation and possible impact of the security event, the measures we have taken or will take, suggestions for
          you to
          independently prevent and reduce risks, and remedial measures for you in a timely manner, in accordance with
          the requirements of laws and regulations. We will promptly inform you of the event-related circumstances via
          push notifications, emails, letters, text messages, and other forms. If it is difficult to inform each
          individual, we will use reasonable and effective methods to publish announcements. At the same time, we will
          also report the handling of user information security events to relevant regulatory authorities as required.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">5.7
          Once you leave 0xSeek and related services to browse or use other websites, services, and content
          resources, we no longer have the capability or direct obligation to protect any personal information you
          submit outside of 0xSeek and related services, regardless of whether you log in, browse, or use the
          aforementioned software, websites based on links or guidance from 0xSeek.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>6.Personalized
            Recommendations</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">To show and recommend more relevant
          information and advertisements, providing services more tailored to your needs, 0xSeek offers
          personalized recommendation services. Below, we will explain in detail the mechanism of personalized
          recommendations and how you can control them.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>6.1
            Mechanism of Personalized Recommendations</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.The scope of personalized
          recommendation services includes displaying information, web pages, articles, and other text or video content.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.To predict your preferences, we may
          collect and use your device information and behavioral information during product use. Behavioral information
          includes actions related to your clicks, follows, collections, searches, queries, browsing, downloading,
          sharing, and transactions while using the product. We will automatically analyze and calculate this
          information, and the system will automatically filter content you may be more interested in for push based on
          the calculation results.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">c.We will provide real-time feedback on
          the recommendation model based on your browsing behavior during product use, continuously adjusting and
          optimizing recommendation results to better provide you with high-quality content.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>6.2
            Control and Management of Personalized Recommendations</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>6.2.1
            Autonomous Control of Receiving Information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">We use recommendation algorithms to
          personalize and display the information content you browse. You can control the content of personalized
          recommendations through the following methods:

        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.
          Deciding to receive personalized push information: You can turn on or off the personalized recommendation
          function through "Me" -> "Settings" -> "Personalized Recommendation Settings". You can choose to turn off
          all personalized recommendations at once. After choosing to turn off all personalized recommendations, the
          "Recommended" channel will no longer be displayed to you, and you will not be able to enjoy personalized
          recommendation services. However, you can still continue to browse content in non-personalized
          recommendation channels such as follows, trending, and top picks.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b. Subscribing to
          information of interest autonomously: In the follow channel, the display will be based on the accounts you
          choose to follow. You can autonomously choose to follow or unfollow accounts.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.Managing Your Personal
            Information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">We place great importance on your
          management of personal information and strive to protect your rights to access, copy, correct, supplement,
          delete, withdraw consent authorization, cancel accounts, file complaints, and set privacy functions, ensuring
          you have the ability to protect your privacy and information security.


        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.1
            Changing or Withdrawing Authorization Scope</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.1.1
            Changing or Withdrawing Sensitive Permission Settings</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">You can change the scope of consent or
          withdraw your authorization by disabling related permissions in the operating system of the device itself.
          After withdrawing authorization, we will no longer collect information related to these
          permissions.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.1.2
            Changing or Withdrawing Authorization for Information Processing</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">Certain business functions and services
          require your information to be completed. When you withdraw consent or authorization, we cannot continue to
          provide you with the functions and services corresponding to the withdrawal of consent or authorization, nor
          will we process your corresponding personal information. However, your decision to withdraw consent or
          authorization will not affect the processing of personal information conducted based on your authorization
          before the withdrawal.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.2
            Your Personal Information Rights</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.2.1
            Accessing, Copying Your Account Information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">You can access and copy your avatar,
          username, position, company, profile, blog address. You can do this in 0xSeek by going to "Me" ->
          tapping on your avatar -> "Edit".</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.2.2
            Accessing, Correcting, Unfollowing Your Accounts; Accessing Followers, Visitors
            Information</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a. Go to 0xSeek -> "Me" -> tap on
          your avatar -> "Following", to access, unfollow accounts you are following in the following
          list.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.Go to 0xSeek -> "Me" -> tap on
          your avatar -> "Followers", to access information of accounts following you.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.2.3
            Accessing, Correcting Your Collections, Likes, Reading History</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.Enter 0xSeek -> "Me" -> "My
          Likes", "Collections" or "Read Articles" to access/correct. </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b. Enter 0xSeek -> "Me" ->
          "Settings" -> "Cache Management", to clear cache information.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.2.4
            Deleting Published Content, Comments</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.To delete published content: Go to
          0xSeek -> "Me" -> tap on your avatar -> go to "Posts" or "Columns" -> enter a specific post -> tap the
          "..." symbol on the top right of the post to perform the delete action.

        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.To delete comment content: You can
          visit the specific comment content and through the page visit delete your comment, cancel your like.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><u><strong>c.Special notice: For
              security and identification purposes (such as service complaints), you may not be able to independently
              modify certain initial registration information provided at the time of registration. If you need to
              modify such registration information, please contact us using the contact information provided in this
              privacy policy.</strong></u></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">In addition to the above, if you or
          other entitled entities have any claims, requests, or questions about the exercise of personal information
          rights granted by relevant laws and regulations, you can contact us through the "Feedback" entry in the "Me"
          -> "Settings" section of the 0xSeek client or the "Feedback" entry at the bottom right corner of the<a
            href="https://www.0xseek.com" data-href="https://www.0xseek.com" target="_blank"
            rel="noopener noreferrer">www.0xseek.com</a>website. We will review the related issues promptly and respond
          within fifteen working days after verifying your user identity.
        </span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.3 Account
            Cancellation</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">If you wish to cancel your 0xSeek
          account, you can inform us of your cancellation request through 0xSeek -> "Me" -> "Settings" -> "Account
          Settings" -> "Account Cancellation" and follow the customer service instructions to provide specified
          materials. We will process your application promptly upon receipt and reply to your request within 15 working
          days or within the timeframe required by law. Before canceling your account, we will verify your personal
          identity, security status, device information, etc. Please note and understand that account cancellation is an
          irreversible action. After you cancel your account, we will delete or anonymize your related information
          unless otherwise provided by law.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">For details on the cancellation process
          and content, <a href="/accountdelete" data-href="/accountdelete" target="_blank"
            rel="noopener noreferrer">please refer to the 0xSeek Account Cancellation Notice.

          </a> 。 </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.4
            Feedback and Complaints</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">You can follow our public complaint or
          reporting procedures. If you believe your personal information rights may have been violated, or if you
          discover clues of infringement of personal information rights (e.g., you believe we collect your personal
          information in violation of laws or agreements), you can contact us through the "Feedback" section under "Me"
          in 0xSeek. After verifying the situation, we will promptly respond to your complaints and reports.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.5
            Accessing the Privacy Policy</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.You can view the full content of this
          privacy policy on the registration page or after logging into your personal account, in the "Me" -> "Settings"
          -> "About" page, <a href="/privacy" data-href="/privacy" target="_blank" rel="noopener noreferrer"></a>or
          through the web interface to view the full content of this privacy policy.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b. Please understand that the services
          mentioned in this privacy policy may vary depending on the phone model, system version, software application
          version, mobile client, etc., you are using. The final products and services are subject to the 0xSeek
          software and related services you use.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>7.6
            Notification of Service Discontinuation</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">If we discontinue operation, we will
          stop collecting your personal information immediately, notify you of the discontinuation of operation through
          individual delivery or announcement, and delete or anonymize your personal information in our possession,
          except as otherwise required by law.</span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>8.Terms for
            Minors</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.If you are under 18 years old and a
          minor, you should read and agree to this privacy policy under the supervision and guidance of your parents or
          other guardians before using 0xSeek and related services. </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.
          If you are the guardian of a minor under 14 years old, you should read and agree to this privacy policy on
          behalf of your ward before using 0xSeek and related services.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">c.We protect minors' personal
          information according to the relevant laws and regulations, only collecting, using, storing, sharing,
          transferring, or disclosing minors' personal information when legally permitted, explicitly consented to by
          the parents or guardians, or necessary to protect the minors. If we find that personal information of minors
          was collected without prior verifiable parental consent, we will try to delete the relevant information as
          soon as possible.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">d.If you are a guardian of a minor and
          have questions about the personal information of the minor under your guardianship, please contact us using
          the contact information provided in this privacy policy.</span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>9.Revision and Notification of
            the Privacy Policy</strong></span></span> </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">a.To provide better services, 0xSeek and related services will update and change from time to time, and we will timely revise this privacy
          policy accordingly. These revisions form part of this privacy policy and have the same legal effect as this
          privacy policy. Without your explicit consent, we will not reduce your rights under the current effective
          privacy policy.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">b.After the update of this privacy
          policy, we will issue the updated version on 0xSeek and announce or notify you of the updated content
          through the official website（<a href="/privacy" data-href="/privacy" target="_blank"
            rel="noopener noreferrer">www.0xseek.com</a>）or other appropriate methods before the updated terms take
          effect, to help you understand the latest version of this privacy policy.
        </span></span></p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2"><strong>10.Contact
            Us</strong></span></span>
    </p>
    <p><span style="font-size:0.6826666666666666rem"><span style="line-height:2">If you have any questions, comments, or
          suggestions about the content of this privacy policy, you can reach us through the "Feedback" option in the
          "Me" section of the 0xSeek client or the "Feedback" option at the bottom right corner of the<a
            href="https://www.0xseek.com" data-href="https://www.0xseek.com" target="_blank"
            rel="noopener noreferrer">www.0xseek.com</a>website. We take your feedback seriously and will address your
          concerns as promptly as possible.
        </span></span></p>
  </div>
</template>

<style>
.draft-container {
  box-sizing: border-box;
  height: 100%;
  line-height: 1.5;
  color: #000;
  font-size: 0.6826666666666656rem;
  padding-bottom: 1.066666666666665rem;
  padding-left: 1.066666666666665rem;
  padding-right: 1.066666666666665rem;
  padding-top: 1.066666666666665rem;

  p {
    font-size: 16px;
  }

  span {
    font-size: 16px;
  }
}
</style>