import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Privacy from "../views/Privacy.vue";
import User from "../views/User.vue";
import Delete from '../views/Delete.vue'
import Intro from '../views/Intro.vue'
import Buy from '../views/Buy.vue'
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "陪拍APP",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Privacy,
    meta: {
      title: "隐私条款",
    },
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta: {
      title: "用户协议",
    },
  },
  {
    path: "/accountdelete",
    name: "Delete",
    component: Delete,
    meta: {
      title: "注销须知",
    },
  },
  {
    path: "/appstore-intro",
    name: "Intro",
    component: Intro,
    meta: {
      title: "零熵",
    },
  },
  {
    path: "/buy",
    name: "Buy",
    component: Buy,
    meta: {
      title: "零熵",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
