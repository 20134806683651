<template>
    <div class="draft-container">
        <p style="text-align:center;"><span style="font-size:1.1946666666666668rem"><span style="line-height:3">0xSeek User Agreement</span></span></p>
        <p style="text-align:start;"><span style="line-height:2">Update Date: January 19, 2024</span></p>
        <p style="text-align:start;"><span style="line-height:2">Effective Date: February 20, 2024</span></p>
        <p style="text-align:start;"></p>
        <h4 id="1、Introduction" style="text-align:start;"><span style="line-height:2">1. Introduction</span></h4>
        <p style="text-align:start;"><span style="line-height:2">Welcome to use 0xSeek and its related
                services!</span></p>
        <p style="text-align:start;"><span style="line-height:2">"0xSeek" and its related services refer to the
                products and services provided to you by Beijing Dake Chuangxiang Technology Co., Ltd. (hereinafter
                referred to as "the company"), which legally owns and operates the client application named "0xSeek" and the website www.0xseek.com, including but not limited to core functions such as
                personalized recommendations, posting information, search queries, interactive communications, and other
                functions. The "0xSeek User Agreement" (hereinafter referred to as "this Agreement") is the
                agreement between you and the company regarding your registration, login, and use (hereinafter
                collectively referred to as "use") of "0xSeek" and its related services.</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">To better serve you, please read and fully understand
                this agreement before starting to use 0xSeek and its related services, especially terms related to
                exemption or limitation of liability, rights licensing and information use, agreement to activate and
                use special individual services, applicable law and dispute resolution, etc. <strong><u>Important
                        content involving your rights and interests will be highlighted in bold for your attention,
                        which you should read carefully.</u></strong> If you are under 18 years old, please read and
                fully understand this agreement with your legal guardian and seek the consent of your legal guardian
                before using 0xSeek and its related services.</span>
        </p>
        <p style="text-align:start;"><strong><u><span style="line-height:2">If you do not agree to this agreement, this
                        will result in us being unable to provide you with complete products and services, and you may
                        choose to stop using them. If you voluntarily choose to agree or use 0xSeek and its
                        related services, it will be deemed that you have fully understood this agreement and agree to
                        be bound by this agreement and other agreements and rules related to 0xSeek and its
                        related services (including but not limited to the 0xSeek Privacy
                        Policy).</span></u></strong>
        </p>
        <p style="text-align:start;"></p>
        <h4 id="2、0xSeek and Related Services" style="text-align:start;"><span style="line-height:2">2. 0xSeek and Related Services</span></h4>
        <p style="text-align:start;"><span style="line-height:2">2.1
                You can obtain the 0xSeek client application or access the website www.0xseek.com by
                pre-installation, download from third parties authorized by the company, etc. <strong><u>If you obtain
                        the 0xSeek client application or website from non-official or unauthorized sources, the
                        company cannot guarantee that the non-official version of the 0xSeek client application or
                        website will function properly, and any losses you suffer as a result will not be related to the
                        company.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">2.2
                The company may develop different application software versions for different terminal devices. You
                should obtain, download, and install the appropriate version according to the actual condition of the
                device. If you no longer use 0xSeek and its related services, you can also uninstall the
                corresponding application software on your own.</span></p>
        <p style="text-align:start;"><span style="line-height:2">2.3
                To better improve user experience and services, the company will periodically update or change 0xSeek and its related services (including but not limited to software modifications, upgrades,
                function enhancements, development of new services, software replacement, etc.). You can choose whether
                to update the corresponding version as needed.</span></p>
        <p style="text-align:start;"><span style="line-height:2">To ensure the security of 0xSeek and related
                services and to improve user services, after part or all of 0xSeek and related services are
                updated, the company will reasonably prompt you in feasible ways (including but not limited to system
                prompts, announcements, site messages, etc.) about the update. You have the right to accept the updated
                version; if you choose not to update, some functions of 0xSeek and related services will be
                limited or unavailable.</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">2.4
                Unless previously authorized in writing by the company, you shall not, in any form, access or use 0xSeek and its related services without authorization, including but not limited to adapting, copying,
                distributing, conducting vertical searches, mirroring, or trading.</span></p>
        <p style="text-align:start;"><span style="line-height:2">2.5
                You understand that to use 0xSeek and its related services, you must prepare the related terminal
                equipment (such as computers, mobile phones, etc.) on your own. Once you open the 0xSeek client
                application or visit the website www.0xseek.com on your terminal device, it is considered that you are
                using 0xSeek and its related services. To fully utilize all functions of 0xSeek, you may
                need to connect your terminal device to the internet, and you understand that you will bear the
                necessary costs (such as data charges, internet fees, etc.).</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">2.6
                The company grants you a personal, non-transferable, non-exclusive, and non-commercial right to legally
                use 0xSeek and its related services. All other rights not expressly authorized by this Agreement
                are still reserved by the company, and you must obtain written permission from the company before
                exercising such rights. At the same time, if the company does not exercise any of the aforementioned
                rights, it does not constitute a waiver of such rights.</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">2.7
                You do not need to register to start using 0xSeek and its related services, but some functions or
                services may be affected. At the same time, you understand that to better use 0xSeek and its
                related services and ensure the security of your account, certain functions and/or certain individual
                service items (such as posting comments, etc.) will require you to provide real identity information for
                real-name registration and log in before use, in accordance with the relevant laws and regulations of
                the state. If necessary, these agreements, rules, etc., will be provided to you in a noticeable manner
                for your review and consent before you plan to use the aforementioned individual services. Once you
                start using the aforementioned services, it is considered that you understand and accept the related
                agreements, rules, and are bound by them.</span>
        </p>
        <p style="text-align:start;"></p>
        <h4><span style="line-height:2">3. About "Account"</span></h4>
        <p style="text-align:start;"><span style="line-height:2">3.1
                "0xSeek" and its related services provide you with a registration channel. You have the right to
                choose a legal character combination as your own account and set a password that meets security
                requirements on your own. The account and password you set are the credentials for you to log in and use
                "0xSeek" and its related services.</span></p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>3.2</u></strong>
                <strong><u>You understand and commit that the account you set must not violate national laws and
                        regulations and the company's related rules. Your account name, avatar, and profile and other
                        registration information and other personal information must not contain illegal and bad
                        information. Without permission from others, you must not use others' names (including but not
                        limited to impersonating others' names, titles, trademarks, avatars, etc. in ways that could
                        cause confusion) to open accounts, nor maliciously register "0xSeek" accounts (including
                        but not limited to frequent registrations, batch registration of accounts, etc.). You must abide
                        by relevant laws and regulations during the account registration and use process and must not
                        commit any acts that infringe on the interests of the state, damage the legitimate rights and
                        interests of other citizens, or harm social morality. The company has the right to review the
                        information you submit.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>3.3</u></strong>
                <strong><u>Your account is for your personal use only and, without the written consent of the company,
                        you are prohibited from gifting, lending, renting, transferring, selling, or otherwise
                        permitting another person to use the account. If the company discovers or has reasonable grounds
                        to believe that the user is not the initial registrant of the account, the company reserves the
                        right to immediately suspend or terminate the provision of services to that registered account,
                        or cancel the account, without any prior notice.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">3.4
                You are responsible for maintaining the security and confidentiality of your personal account and
                password and bear all legal responsibilities for activities conducted under your registered account
                name, including but not limited to any changes to data, statements made, payment of funds, and other
                operational behaviors that may incur legal responsibilities. You should take your account and password
                confidentiality seriously and never disclose your account and password to others under any
                circumstances. If you discover that someone else has used your account without permission or any other
                security vulnerabilities, you should immediately notify the company.</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">3.5
                If you lose your account or forget your password, you can follow the company's account appeal process to
                timely request to retrieve your account or password. You understand and recognize that the company's
                password retrieval mechanism only requires identification that the information filled in the appeal form
                is consistent with the system records, and cannot identify whether the appellant is the actual right
                holder of the account. The company specifically reminds you to properly keep your account and password.
                You should log out safely after use. If you suffer account theft or password loss due to improper
                custody or other non-resistible factors, you will bear the corresponding responsibilities.</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>3.6</u></strong>
                <strong><u>When registering, using, and managing an account, you should ensure that the identity
                        information provided at the time of account registration is true. You should use real, accurate,
                        legal, and valid identification materials and necessary information when registering and
                        managing your account. According to the relevant national laws and regulations, to use some
                        functions of "0xSeek" and its related services, you need to fill in real identity
                        information. Please complete real-name authentication according to relevant legal regulations
                        and promptly update the aforementioned relevant information. If the materials you submit or the
                        information provided is inaccurate, untrue, illegal, or if the company has reason to suspect
                        that the materials are erroneous, false, or illegal, the company has the right to refuse to
                        provide certain functions, and you may not be able to use "0xSeek" and its related
                        services or some functions will be limited during use.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">3.7
                In addition to registering a "0xSeek" account yourself, users can also authorize the use of
                legally owned, real-name registered third-party software user accounts to log in and use "0xSeek"
                and its related services, except where third-party software or platforms restrict or prohibit this. When
                users log in using the aforementioned existing accounts, they should ensure that the corresponding
                accounts have been registered with real names and are equally subject to the terms of this
                agreement.</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">3.8
                Once you complete the registration, log in to "0xSeek", and perform reasonable and necessary
                identity verification, you can browse and modify your personal identity information submitted at any
                time. You understand and agree that, for security and identity verification reasons (such as account or
                password recovery appeal services, etc.), you may not be able to modify some of the initial registration
                information provided and other verification information. You can also apply for account cancellation,
                and the company will assist you in canceling your account after completing reasonable and necessary
                verification of personal identity, security status, device information, etc., and delete all information
                related to your account as per your request, except as otherwise provided by law.</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">3.9 You understand and agree that to fully utilize
                account resources, if you do not log in for the first time after registration or do not log into the
                account for more than two months consecutively, the company has the right to reclaim your
                account.</span></p>
        <p style="text-align:start;"></p>
        <h4 id="4、Protection of User Personal Information" style="text-align:start;"><span style="line-height:2">4.
                Protection of User Personal Information</span></h4>
        <p style="text-align:start;"><span style="line-height:2">The company and you are committed to the protection of
                your personal information (information that can identify the user independently or in combination with
                other information). Protecting user personal information is one of the company's basic principles.
                During the use of 0xSeek and related services, you may need to provide your personal information
                (including but not limited to your name, telephone number, location information, etc.) so that the
                company can offer you better services and corresponding technical support. The company will use
                encryption technology, anonymization, and other matching technical measures as well as other security
                measures to protect your personal information. For more information on the protection of user personal
                information, please see the 0xSeek Privacy Policy.</span>
        </p>
        <p style="text-align:start;"></p>
        <h4 id="5、User Behavior Standards" style="text-align:start;"><span style="line-height:2">5. User Behavior
                Standards</span></h4>
        <p style="text-align:start;"><span style="line-height:2">5.1 User Behavior Requirements</span></p>
        <p style="text-align:start;"><span style="line-height:2">You are responsible for your behavior in using 0xSeek and its related services. Unless permitted by law or authorized in advance in writing by the
                company, you shall not engage in any of the following behaviors while using 0xSeek and its related
                services:</span></p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>5.1.1</u></strong>
                <strong><u>Use any unauthorized plugins, add-ons, systems, or third-party tools to interfere with,
                        disrupt, modify, or affect the normal operation of 0xSeek and its related
                        services.</u></strong></span></p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>5.1.2</u></strong>
                <strong><u>Engage in any behavior that jeopardizes computer network security using or targeting 0xSeek and its related services, including but not limited to:</u></strong></span></p>
        <p style="text-align:start;"><span style="line-height:2">(1) Illegal intrusion into others' networks,
                interference with normal functions of others' networks, theft of network data, and other activities that
                jeopardize network security;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(2) Provide specialized programs or tools for engaging
                in intruding into networks, disrupting the normal functions and protective measures of networks,
                stealing network data, and other activities that jeopardize network security;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(3) Knowingly assisting others in engaging in
                activities that jeopardize network security by providing technical support, advertising promotion,
                payment settlement, and other help;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(4) Use unauthorized data or access unauthorized
                servers/accounts;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(5) Enter public computer networks or others' computer
                systems without permission and delete, modify, or add stored information;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(6) Attempt without permission to probe, scan, test the
                weaknesses of 0xSeek's system or network, or other actions that breach network security;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">(7) Attempt to interfere with or disrupt the normal
                operation of 0xSeek's system or website, intentionally spread malicious programs or viruses, and
                other actions that disrupt normal network information services;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(8) Forge TCP/IP packet names or parts of names;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">(9)
                Reverse engineer, decompile, compile, or otherwise try to discover the source code of 0xSeek</span></p>
        <p style="text-align:start;"><span style="line-height:2">(10) Maliciously register 0xSeek accounts,
                including but not limited to frequent, batch registration of accounts;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(11) Other behaviors that violate laws and regulations,
                this agreement, company's related rules, or infringe on others' legal rights.</span></p>
        <p style="text-align:start;"><span style="line-height:2">5.1.3 If the company has reason to believe that any of
                your actions violate or may violate the above agreements, the company has the right to independently
                judge and take measures according to the situation, including but not limited to issuing a warning,
                refusing to publish, immediately stopping the transmission of information, deleting comments,
                temporarily banning from speaking, restricting the account's part or all functions until permanently
                closing the account. The company has the right to announce the results of such actions and has the
                discretion to decide whether to restore the use of the related account based on actual situations.
                Actions suspected of violating laws or engaging in illegal crimes will have their records preserved,
                and, as required by law, reported to the relevant authorities and cooperate with their
                investigations.</span></p>
        <p style="text-align:start;"><span style="line-height:2">5.2 Information Content Standards</span></p>
        <p style="text-align:start;"><span style="line-height:2">5.2.1
                After completing real-name authentication as required, you may log into "0xSeek" to post content,
                comment on posts, etc., using your registered account or "0xSeek" cooperation platform
                account.</span></p>
        <p style="text-align:start;"><span style="line-height:2">5.2.2
                The company is committed to promoting civilized, rational, friendly, and high-quality exchanges of
                opinions in posting information and comments. While promoting the development of posting information,
                interactive communications, and comment services, the company continuously strengthens the management
                capability of information security, perfects the rules for posting information and comments, and
                fulfills social responsibilities, complying with national laws and regulations, respecting citizens'
                legal rights, and respecting social morals.</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>5.2.3</u></strong>
                <strong><u>You commit that the content you post, comment, disseminate should voluntarily comply with the
                        constitution, laws, regulations, public order, social morals, socialist system, national
                        interests, citizens' legal rights, moral customs, and truthfulness of information. You agree not
                        to produce, copy, publish, or disseminate information content prohibited by laws and regulations
                        including but not limited to:</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">(1) Opposing the basic principles established by the
                constitution;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(2) Endangering national security, leaking state
                secrets;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(3) Subverting state power, overthrowing the socialist
                system, inciting secession, undermining national unity;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(4) Damaging the nation's honor and interests;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">(5) Promoting terrorism, extremism;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(6) Inciting ethnic hatred, ethnic discrimination,
                undermining national unity;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(7) Inciting regional discrimination, regional
                hatred;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(8) Undermining the national religious policy,
                advocating cults and superstitions;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(9) Spreading rumors, false information, disturbing the
                economic and social order, undermining social stability;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(10) Spreading violence, obscenity, pornography,
                gambling, homicide, terror, or abetting crimes;</span></p>
        <p style="text-align:start;"><span style="line-height:2">And so on, up to (24) other information violating laws,
                regulations, policy, public order and morals, interfering with 0xSeek's normal operations or
                infringing other users or third parties' legal rights.</span></p>
        <p style="text-align:start;"><span style="line-height:2">5.3
                The company has established a complaint and reporting mechanism. You can report various illegal and
                rule-violating behaviors, illegal dissemination activities, illegal harmful information, etc., through
                the "Feedback" entrance in the 0xSeek client or the "Suggestions and Feedback" entrance at the
                bottom right corner of the www.0xseek.com website. The company will handle your complaints and reports
                in a timely manner to jointly create a clean online space.</span>
        </p>
        <p style="text-align:start;"></p>
        <h4 id="6、Regulations on the Use of 0xSeek Information Content" style="text-align:start;"><span
                style="line-height:2">6. Regulations on the Use of 0xSeek Information Content</span></h4>
        <p style="text-align:start;"><span style="line-height:2">6.1
                Without the written permission of the company, you may not engage in any form of self-conduct or
                authorize, allow, assist any third party to conduct the following behaviors regarding the information
                content of 0xSeek and its related services:</span></p>
        <p style="text-align:start;"><span style="line-height:2">(1) Copy, read, use the information content of 0xSeek and its related services for commercial purposes, including but not limited to advertising,
                increasing reading or browsing volume, etc.;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(2) Unauthorized editing, organizing, arranging the
                information content of 0xSeek and its related services and displaying it in channels outside the
                source page of 0xSeek and its related services;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(3) Using any form of identification, special codes,
                etc., to guide, transfer, hijack traffic or reading volume of 0xSeek and its related services'
                information content, causing adverse effects;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">(4) Other behaviors that illegally obtain or use the
                information content of 0xSeek and its related services.</span></p>
        <p style="text-align:start;"><span style="line-height:2">6.2
                After obtaining written permission from the company, any sharing, forwarding, and other behaviors
                regarding the information content of 0xSeek and its related services should also comply with the
                following standards:</span></p>
        <p style="text-align:start;"><span style="line-height:2">(1) Any related data such as search hot words, hit
                rate, classification, search volume, click rate, reading volume, etc., obtained through crawling,
                statistics, acquisition, etc., shall not be publicized, provided, disclosed to any third party without
                the company's prior written consent;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">(2) Do not make any form of changes to the source web
                pages of 0xSeek and its related services, including but not limited to links to the homepage
                (profile page) and other entrances, and do not obstruct the display of the source page of 0xSeek
                and its related services in any way;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">(3) Take secure, effective, and strict measures to
                prevent the information content of 0xSeek and its related services from being illegally obtained
                by third parties through any form, including but not limited to "spider" programs;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">(4) Do not use the obtained data content for any
                purpose outside the scope authorized in writing by the company, for any form of sale or commercial use,
                or disclose, provide, or allow third parties to use in any way;</span></p>
        <p style="text-align:start;"><span style="line-height:2">(5) Any behavior of sharing, forwarding, copying the
                information content of 0xSeek and its related services to any third party should comply with other
                standards and norms established by the company for this purpose.</span></p>
        <p style="text-align:start;"></p>
        <h4 id="7、Breach of Contract" style="text-align:start;"><span style="line-height:2">7. Breach of Contract</span>
        </h4>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>7.1</u></strong>
                <strong><u>In response to your breach of this agreement or other service terms, the company has the
                        right to make an independent judgment and take actions according to the situation, such as
                        issuing a prior warning, refusing to publish, immediately stopping the transmission of
                        information, deleting comments, temporarily banning from speaking, restricting account functions
                        partially or completely until permanently closing the account. The company has the right to
                        announce the handling results and decides whether to restore the use of the related account
                        based on actual situations. Suspected illegal activities or crimes will be recorded and reported
                        to the competent authorities in accordance with the law and cooperate with their
                        investigations.</u></strong></span>
        </p>
        <p style="text-align:start;">
            <span style="text-align:start;" />
        </p>
        <h4 id="6、Standards for the Use of 0xSeek Information Content" style="text-align:start;"><span
                style="line-height:2">6. Standards for the Use of 0xSeek Information Content</span></h4>
        <p style="text-align:start;"><span style="line-height:2">6.1
                Without the company's written permission, you or any third party are not allowed to engage in the
                following behaviors regarding the information content within 0xSeek and its related
                services:</span></p>
        <p style="text-align:start;"><span style="line-height:2">（1）Copy, read, or use the information content of 0xSeek and its related services for commercial purposes including but not limited to advertising,
                increasing readership, views, etc.;</span></p>
        <p style="text-align:start;"><span style="line-height:2">（2）Edit, organize, or arrange the information content
                of 0xSeek and its related services and display it on channels outside of the source page of 0xSeek and its related services without authorization;</span></p>
        <p style="text-align:start;"><span style="line-height:2">（3）Use any form of identification, codes, or any other
                means to generate traffic, guide, transfer, hijack views for the information content of 0xSeek and
                its related services by yourself or assist a third party in such actions, causing adverse
                effects;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">（4）Other illegal behaviors to obtain or use the
                information content of 0xSeek and its related services.</span></p>
        <p style="text-align:start;"><span style="line-height:2">6.2
                After obtaining the company's written permission, your sharing, forwarding, and other behaviors of the
                information content of 0xSeek and its related services should also comply with the following
                standards:</span></p>
        <p style="text-align:start;"><span style="line-height:2">（1）Regarding the data such as related search hot words,
                hit rates, classifications, search volumes, click rates, reading volumes, etc., obtained by capture,
                statistics, or acquisition, unless agreed upon in advance in writing by the company, you should not
                disclose, provide, or leak the above data to any third party in any manner;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">（2）You must not make any form of alteration to the
                source web page of 0xSeek and its related services, including but not limited to the links to the
                homepage (profile page) of 0xSeek and its related services, nor should you block, insert, pop-up,
                or otherwise hinder the display of the source page of 0xSeek and its related services;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">（3）You should take safe, effective, and strict measures
                to prevent the information content of 0xSeek and its related services from being illegally
                obtained by a third party through any form including but not limited to "spider" programs;</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">（4）You should not use the content data for purposes
                outside the scope authorized by the company in writing, engage in any form of sale or commercial use, or
                disclose, provide, or allow third parties to use it in any way;</span></p>
        <p style="text-align:start;"><span style="line-height:2">（5）Any behavior of sharing, forwarding, copying the
                information content of 0xSeek and its related services to any third party should comply with other
                standards and criteria set by the company.</span></p>
        <p style="text-align:start;"></p>
        <h4 id="7、Breach of Contract" style="text-align:start;"><span style="line-height:2">7. Breach of Contract</span>
        </h4>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>7.1</u></strong>
                <strong><u>For your behavior that violates this agreement or other service terms, the company has the
                        right to independently judge and take measures such as advance warning, refusal to publish,
                        immediate cessation of information transmission, deletion of comments, short-term prohibition on
                        speaking, restriction of part or all functions of the account until permanently closing the
                        account according to the situation. The company has the right to announce the results of
                        processing and decides whether to restore the use of the related account based on actual
                        situations. Suspected violations of laws and regulations or criminal acts will be recorded, and,
                        as required by law, reported to the relevant authorities and cooperate with their
                        investigations.</u></strong></span></p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>7.2</u></strong>
                <strong><u>If your violation of this agreement or other service terms leads to third-party complaints or
                        legal claims, you must handle and bear all legal responsibilities that may arise from such
                        complaints or claims by yourself. If your illegal or infringing actions cause the company and
                        its affiliates to compensate any third party or suffer penalties from state authorities, you
                        must also fully compensate the company and its affiliates for all losses
                        suffered.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>7.3</u></strong>
                <strong><u>The company respects and protects the intellectual property rights, reputation rights, name
                        rights, privacy rights, and other legal rights of users and others. You guarantee that the text,
                        images, videos, audio, and other content uploaded, published by you through 0xSeek and its
                        related services do not infringe any third party's intellectual property rights, reputation
                        rights, name rights, privacy rights, and other rights and interests. Otherwise, the company has
                        the right to remove the alleged infringing content upon receiving notification from the rights
                        holder or related parties. Regarding all rights claims made by third parties, you should handle
                        and bear all legal responsibilities that may arise from such claims; if your infringing actions
                        cause losses to the company and its affiliates (including economic losses, reputation damages,
                        etc.), you must also fully compensate for all losses suffered by the company and its
                        affiliates.</u></strong></span>
        </p>
        <p style="text-align:start;"></p>
        <h4 id="8、Changes, Interruption, and Termination of Service" style="text-align:start;"><span
                style="line-height:2">8. Changes, Interruption, and Termination of Service</span></h4>
        <p style="text-align:start;"><span style="line-height:2">8.1
                You understand and agree that the services provided by 0xSeek are based on the current state of
                technology and conditions. The company does its utmost to provide services to you, ensuring the
                continuity and security of the service. However, the company cannot foresee or guard against technical
                and other risks at all times, including but not limited to force majeure, network reasons, third-party
                service defects, etc., that may lead to service interruption or inability to use 0xSeek and its
                related services and other losses and risks.</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">8.2
                You understand and agree that for the overall operation of the service and platform operation security
                needs, the company has the right to decide the settings and scope of the service/function, and modify,
                interrupt, suspend, or terminate 0xSeek and its related services depending on specific
                circumstances.</span></p>
        <p style="text-align:start;"></p>
        <h4 id="9、Advertising" style="text-align:start;"><span style="line-height:2">9. Advertising</span></h4>
        <p style="text-align:start;"><span style="line-height:2">9.1
                You understand and agree that during the use of 0xSeek and its related services, the company may
                push information, advertisements, or brand promotion services relevant to you, and the company will
                display advertisements related to 0xSeek and its related services or third-party suppliers,
                partners in 0xSeek and its related services.</span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">9.2 The company fulfills advertising and promotional
                related obligations in accordance with legal requirements. You should judge the authenticity and
                reliability of the advertisement or promotional information by yourself and bear responsibility for your
                judgment actions. Except as explicitly stipulated by law, the company does not bear any responsibility
                for any profit, business reputation, data loss, or other tangible or intangible losses you suffer due to
                advertisements or promotional information, purchase, transactions, or the content previously
                mentioned.</span>
        </p>
        <p style="text-align:start;"></p>
        <h4 id="10、Intellectual Property" style="text-align:start;"><span style="line-height:2">10. Intellectual
                Property</span></h4>
        <p style="text-align:start;"><span style="line-height:2">10.1
                The intellectual property rights of the content provided in 0xSeek and its related services
                (including but not limited to software, technology, programs, web pages, text, pictures, graphics,
                audio, video, layout design, electronic documents, etc.) belong to the company or relevant rights
                holders . The copyright, patent right, and other intellectual property rights of the software relied
                upon to provide this service are owned by the company. Without the company's permission, no one may use
                (including but not limited to monitoring, copying, transmitting, displaying, mirroring, uploading,
                downloading through any robots, spiders, or other devices or processes) the content of 0xSeek and
                its related services.</span></p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>10.2</u></strong>
                <strong><u>You understand and agree that any text, images, videos, audio, etc., that you upload, publish
                        through 0xSeek and its related services are created by you or you have obtained legal
                        authorization. The intellectual property rights of any content uploaded or published by you
                        through 0xSeek belong to you or the original copyright holders.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>10.3</u></strong>
                <strong><u>You acknowledge, understand, and agree to grant the company and its affiliates a worldwide,
                        royalty-free, non-exclusive, sublicensable (through multiple tiers) right to use, copy,
                        distribute, perform, display, and prepare derivative works of the content (including but not
                        limited to text, images, graphics, audio, video, and audio/video music, sounds, dialogues, etc.)
                        that you upload, publish or broadcast through 0xSeek and its related services. This
                        includes, but is not limited to, using the content for promotional, advertising, or other
                        purposes related to 0xSeek client applications or websites, the company, and/or
                        company-branded activities. You hereby confirm and agree that the aforesaid rights include the
                        right and license to use, reproduce, display, distribute the content, and to make derivative
                        works of the content, in whole or in part, in connection with the services and the operation of
                        the 0xSeek's business, including but not limited to for promoting and redistributing part
                        or all of the 0xSeek services (and derivative works thereof) in any media formats and
                        through any media channels.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>10.4</u></strong>
                <strong><u>You confirm and agree to authorize the company to enforce copyright claims on your behalf for
                        the content you have uploaded and published that holds intellectual property rights. The
                        enforcement methods include but are not limited to monitoring infringement activities, sending
                        copyright infringement notices, filing lawsuits or arbitration, mediation, settlement, etc. The
                        company has the right to decide on the enforcement actions and implement them
                        independently.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">10.5
                The company provides technical support and is responsible for the development and operation of 0xSeek and its related services. The company owns all rights, titles, and interests in and to the data
                and information generated during the development and operation of 0xSeek and its related services
                within the scope permitted by laws and regulations.</span></p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>10.6</u></strong>
                <strong><u>Under no circumstances should you use the company's trademarks, including but not limited to
                        "0xSeek", without permission. Without the company's prior written consent, you shall not
                        display, use, or apply for registration of any trademarks or domain names, etc., nor imply to
                        others that you have the right to display, use, or otherwise deal with such trademarks. Any loss
                        to the company or others caused by your unauthorized use of the company's trademarks or logos
                        will be your responsibility.</u></strong></span>
        </p>
        <p style="text-align:start;"></p>
        <h4 id="11、Disclaimer" style="text-align:start;"><span style="line-height:2">11. Disclaimer</span></h4>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>11.1</u></strong>
                <strong><u>You understand and agree that 0xSeek and its related services may be influenced or
                        interfered with by various factors, and the company does not guarantee (including but not
                        limited to):</u></strong></span></p>
        <p style="text-align:start;"><strong><u><span style="line-height:2">11.1.1
                        That 0xSeek and its related services fully meet all your use
                        requirements;</span></u></strong></p>
        <p style="text-align:start;"><strong><u><span style="line-height:2">11.1.2
                        That 0xSeek and its related services will operate without interruptions, be timely,
                        secure, reliable, or error-free; any software, services, or other materials you obtain from the
                        company will meet your expectations;</span></u></strong></p>
        <p style="text-align:start;"><strong><u><span style="line-height:2">11.1.3 That any errors in 0xSeek and
                        its related services will be corrected.</span></u></strong></p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>11.2</u></strong>
                <strong><u>In cases involving network information about borrowing or other property matters, account
                        passwords, advertisements or promotions, etc., you should treat them with caution and make your
                        own judgments. The company does not undertake any direct, indirect, incidental, special,
                        consequential, or punitive damages for profit, business reputation, data loss, or other tangible
                        or intangible losses you suffer due to such information.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>11.3</u></strong>
                <strong><u>You understand and agree that during the use of 0xSeek and its related services, you
                        may encounter force majeure and other factors (force majeure refers to unforeseeable,
                        unavoidable, and insurmountable objective events), including but not limited to natural
                        disasters (such as floods, earthquakes, typhoons, etc.), government actions, wars, strikes,
                        disturbances, etc. In such cases, the company will strive to repair in the first time and
                        promptly, but will not bear any responsibility for losses caused by force majeure within the
                        scope permitted by law.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>11.4</u></strong>
                <strong><u>The company has the right to deal with illegal or rule-violating content according to the
                        rights obtained under this agreement, but this does not constitute an obligation of the company,
                        and the company does not guarantee that it will detect or deal with such behavior in a timely
                        manner.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>11.5</u></strong>
                <strong><u>You understand and agree that regarding 0xSeek and its related services, the company
                        does not provide any kind of express or implied warranties or conditions, including but not
                        limited to warranties of merchantability, fitness for a particular purpose, and
                        non-infringement. Your use of 0xSeek and its related services is at your own
                        risk.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>11.6</u></strong>
                <strong><u>You understand and agree that the agreement is aimed at complying with national laws and
                        regulations, maintaining public order and good customs, protecting the rights of others, and the
                        company will make judgments to the best of its ability in accordance with relevant laws and
                        regulations. However, the company does not guarantee that its judgment will be completely
                        consistent with that of judicial or administrative organs, and any consequences arising
                        therefrom shall be understood and agreed to be borne by you.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>11.7</u></strong>
                <strong><u>In any case, the company shall not be liable for any indirect, consequential, punitive,
                        accidental, special, or penal damages, including but not limited to those resulting from your
                        use of 0xSeek and its related services leading to loss of profits. The total liability of
                        the company to you, for any reason or any form of action, will not exceed the amount you have
                        paid to the company for using 0xSeek and its related services (if
                        any).</u></strong></span>
        </p>
        <p style="text-align:start;"></p>
        <h4 id="12、Special Agreement on Single Services and Third-party Services" style="text-align:start;"><span
                style="line-height:2">12. Special Agreement on Single Services and Third-party Services</span>
        </h4>
        <p style="text-align:start;"><span style="line-height:2">12.1
                0xSeek and its related services include information or information content links obtained by the
                company in various legal ways, as well as other single services legally operated by the company and/or
                its affiliates. <strong><u>You can use the aforementioned single services in 0xSeek. Some single
                        services may require you to accept specifically formulated agreements or other rules that bind
                        you and the service provider, and these agreements, rules , etc., will be provided to you in a
                        noticeable manner for your review and consent before you plan to use the aforementioned
                        individual services. Once you start using the aforementioned services, it is considered that you
                        understand and accept the related agreements, rules, and are bound by them. If the single
                        service does not specify a usage period, or if the usage period is not marked as "permanent,"
                        "indefinite," or "unrestricted," then the usage period for these products or services shall be
                        from the time you start using the product or service until the product or service is removed
                        from 0xSeek.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>12.2</u></strong>
                <strong><u>If you use third-party provided software and related services within 0xSeek, in
                        addition to adhering to this agreement and 0xSeek's relevant rules, you may also need to
                        agree to and comply with third-party agreements, privacy policies, and relevant rules. Should
                        disputes, losses, or damages arise from third-party software and related services, you and the
                        third party will resolve them amongst yourselves. The company does not bear any responsibility
                        towards you or any third party for such issues.</u></strong></span>
        </p>
        <p style="text-align:start;"></p>
        <h4 id="13、Terms of Use for Minors" style="text-align:start;"><span style="line-height:2">13. Terms of Use for
                Minors</span></h4>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>13.1</u></strong>
                <strong><u>If the user is a minor under 18 years of age, they should use 0xSeek and its related
                        services under the supervision and guidance of their guardian, and only after carefully reading
                        this agreement and obtaining the consent of their guardian.</u></strong></span></p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>13.2</u></strong>
                <strong><u>The company attaches great importance to the protection of minors' personal information. When
                        minors fill in personal information, they should strengthen their awareness of personal
                        protection and be cautious. Minors should use 0xSeek and its related services under the
                        guidance of their guardian.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>13.3</u></strong>
                <strong><u>Minors understand that if they violate laws and regulations, this agreement, they and their
                        guardian should bear all legal responsibilities that may arise in accordance with the
                        law.</u></strong></span></p>
        <p style="text-align:start;"><strong><u><span style="line-height:2">13.4 Special Reminder for Minor
                        Users:</span></u></strong></p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>13.4.1</u></strong>
                <strong><u>Youth users should use 0xSeek and its related services under the supervision of their
                        guardians, use the internet properly within a reasonable range, avoid addiction to the virtual
                        cyberspace, and cultivate good internet habits.</u></strong></span></p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>13.4.2</u></strong>
                <strong><u>Minor users must adhere to the "National Youth Internet Civilization Convention": (1) Be good
                        at online learning and do not browse bad information; (2) Be honest and friendly in
                        communication, do not insult or cheat others; (3) Enhance self-protection awareness, do not meet
                        with net friends arbitrarily; (4) Maintain network security, do not disrupt network order; (5)
                        Benefit physical and mental health, do not indulge in virtual space.</u></strong></span>
        </p>
        <p style="text-align:start;">
            <span style="line-height:2"><strong><u>13.5</u></strong>
                <strong><u>To better protect the privacy rights of minors, the company reminds users to be cautious in
                        publishing content that includes materials of minors. Once published, it is deemed that users
                        agree to display minors' information, images, voices, etc., in 0xSeek and its related
                        services, and allow the company to use and process such content related to minors according to
                        this agreement.</u></strong></span><span style="line-height:2"><strong><u>14.1</u></strong>
            <strong><u>The formation, effectiveness, performance, interpretation, and dispute resolution of this
                    agreement shall be governed by the laws of the People's Republic of China. If any provision of this
                    agreement is invalid or unenforceable due to conflict with the laws of the People's Republic of
                    China, those terms shall be reinterpreted as closely as possible to their original intent, and the
                    remaining provisions of this agreement shall still remain in full force and
                    effect.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>14.2</u></strong>
                <strong><u>The place of signing this agreement is Haidian District, Beijing, People's Republic of China.
                        In case of any dispute between you and the company regarding this agreement, both parties should
                        try to resolve it through friendly negotiation; if the negotiation fails, you agree to submit
                        the dispute to the people's court of the place where this agreement is signed for litigation
                        resolution.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2"><strong><u>14.3</u></strong>
                <strong><u>To provide you with better services or due to adjustments in national laws, regulations,
                        policies, 0xSeek and its related services will be updated and changed from time to time.
                        We will timely revise this agreement accordingly, and such revisions will form part of this
                        agreement. After updating the agreement, we will post the updated version on the 0xSeek
                        client application or website and remind you of the updated content in an appropriate way before
                        the updated terms take effect, so that you can learn about the latest version of the agreement
                        in time. You can also review the latest version of the agreement terms on the website's
                        homepage, client application's registration/login page, or "Settings" page. If you have
                        objections to the modified agreement terms, please stop logging in or using 0xSeek and its
                        related services immediately. If you continue to log in or use 0xSeek and its related
                        services, it is deemed that you have understood and agreed to the content of the revised
                        agreement.</u></strong></span>
        </p>
        <p style="text-align:start;"><span style="line-height:2">14.4 The headings in this agreement are for convenience
                and reading only and do not affect the interpretation of any provision of this agreement.</span></p>
        <p style="text-align:start;"></p>
        <p style="text-align:start;"><span style="line-height:2">14.5
                You and the company are independent entities. Under no circumstances shall this agreement constitute any
                express or implied guarantee or condition by the company to the user, nor does it constitute an agency,
                partnership, joint venture, or employee-employer relationship between the parties.</span></p>
    </div>
</template>

<style>
.draft-container {
    box-sizing: border-box;
    height: 100%;
    line-height: 1.5;
    color: #000;
    font-size: 0.6826666666666656rem;
    padding-bottom: 1.066666666666665rem;
    padding-left: 1.066666666666665rem;
    padding-right: 1.066666666666665rem;
    padding-top: 1.066666666666665rem;

    p {
        font-size: 16px;
    }

    span {
        font-size: 16px;
    }
}
</style>