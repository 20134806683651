<template>
    <div class="appstore-intro">
        <h1>如何使用淘宝平台购买非大陆Apple ID</h1>
        <h2>第一步 打开淘宝APP或官网</h2>
        <h2>第二步 搜索“iOS美版” 、“iOS北美”或者“美国AppStore”等关键词（如没有结果，点击一下按销量排序）</h2> <img
            src="https://cdn.0xseek.xyz/f32305c1c8fea5dcaf1db03eef909a54.png">
        <h2>第三步 点击进入宝贝详情页，找到对应商品点击客服，确认售后无问题进行付款。</h2> <img
            src="https://cdn.0xseek.xyz/bdea4fc47a8876a44ab8ed4a99720fca.png">
        <h2>第四步 一般来说，此时会自动收到卖家发送的非大陆 Apple ID 账号密码</h2> <img
            src="https://cdn.0xseek.xyz/cb3a3c8a1d688e00936ff467cc029960.png">
        <p>此时，您就获得了一个非大陆Apple ID</p>
        <p class="appstore-intro-help">这些账号都是淘宝商家自行售卖，该 Apple ID 与 零熵 无关。</p>
    </div>
</template>


<style>
.appstore-intro {
    color: #37352f;
    margin: 0 auto;
    max-width: 700px;
    padding: 15px 15px 100px;
    width: calc(100% - 30px);

    p {
        margin: 10px 0;
        text-indent: 1rem;
    }

    img {
        display: block;
        margin: 10px auto;
        max-width: 60%;
    }
}

.appstore-intro-help {
    color: #666;
}
</style>