<template>
    <div class="draft-container">
        <p>在你申请注销零熵帐号之前，请你认真阅读、理解并同意《零熵帐号注销须知》（以下称“注销须知”）。 </p>
        <p>【特别提示】在此善意提醒你，注销零熵帐号为不可恢复的操作，注销帐号后你将无法再使用本零熵帐号或找回你浏览、关注、添加、绑定的任何内容或信息
            <strong>（即使你使用相同的手机号码或邮箱再次注册并使用零熵）</strong>。
        </p>
        <p>建议你在注销前自行备份零熵帐号相关的所有信息，并请确认与本零熵帐号相关的所有服务均已进行妥善处理。注销成功后，我们将删除你的个人信息，或对其进行匿名化处理。请你知悉并理解，根据相关法律法规规定相关日志记录零熵将保留不少于6个月的时间。
        </p>
        <p><strong>1.我们对你注销零熵帐号的决定深表遗憾。如果你仍执意注销帐号，你的帐号需同时满足以下条件：</strong> </p>
        <p>1.1 帐号处于安全状态： 帐号处于正常使用状态，无被盗风险。 </p>
        <p>1.2 帐号财产已结清，没有未完成和/或存在争议的服务： 帐号中没有资产、欠款、未结清的资金和虚拟权益；本帐号及通过本帐号接入的第三方中没有未完成和/或存在争议的服务。 </p>
        <p>1.3 帐号权限解除： 帐号已解除与其他产品、网站授权登录或绑定关系。 </p>
        <p>1.4 帐号无任何纠纷，包括投诉举报或被投诉举报。 </p>
        <p><strong>2.在你的帐号注销期间，如果你的帐号涉及争议纠纷，包括但不限于：</strong> </p>
        <p>2.1 投诉、举报、诉讼、仲裁、国家有权机关调查等，你知晓并理解，零熵有权自行决定是否终止本帐号的注销而无需另行得到你的同意。 </p>
        <p><strong>3.注销零熵帐号，你将无法再使用本零熵帐号，也将无法找回本零熵帐号中及与帐号相关的任何内容或信息，包括但不限于：</strong> </p>
        <p>3.1 本零熵帐号的个人资料和历史信息（包括但不限于头像、用户名、发布内容、浏览记录、关注、收藏、服务信息等）都将无法找回； </p>
        <p>3.2 你将无法登录、使用本零熵帐号，你的粉丝将无法继续关注你或通过本帐号与你互动； </p>
        <p>3.3 你通过本零熵帐号使用、授权登录或绑定本零熵帐号后使用的零熵或第三方服务的相关记录将无法找回。你将无法再登录、使用上述服务，你曾获得的虚拟权益等财产性利益视为你自动放弃，将无法继续使用。
            <strong>你理解并同意，零熵无法协助你重新恢复上述服务。</strong>
        </p>
        <p><strong>4.注销本零熵帐号并不代表注销前的帐号中的行为和相关责任得到豁免或减轻。</strong></p>
    </div>
</template>
