<template>
  <div class="root">
    <div class="top">
      <img src="../assets/logo.png" class="logo stick" />
      <p class="appName">{{ appName }}</p>
      <div class="btn download" @click="handleDownLoad">立即下载</div>
    </div>
    <div class="main">
      <div class="toast">
        <p>{{ toastTop }}</p>
        <p class="sub-title">{{ toastMid }}</p>
      </div>
      <div class="desc">
        {{ desc }}
      </div>
      <div class="download-info">
        <div class="apple-down-container">
          <div class="btn download apple-download" @click="handleDownLoadType('ios')">
            <span>苹果端立即下载</span>
            <span class="other-desc">需 AppStore 海外账户</span>
          </div>
          <RouterLink to="/appstore-intro" class="apple-more-link">
            <div class="downloadn-tips" @click="handleCheckDownload">
              <p data-v-32d3aae4="" class="other-download-text">
                <img src="../assets/question.png" class="icon-question">
                <span data-v-32d3aae4="" class="text">了解如何用其他方式下载</span>
                <img src="../assets/right.png" class="icon-question">
              </p>
            </div>
          </RouterLink>

        </div>
        <div class="btn download apple-download" @click="handleDownLoadType('android')">
          安卓端立即下载
        </div>
      </div>
      <img class="post" src="../assets/seekapp.png" />
    </div>
    <div class="bottom">
      <img class="logo" src="../assets/logo.png" />
      <div class="info">
        <p class="info-name">{{ appName }}</p>
        <p class="info-desc">{{ toastTop }}</p>
        <!-- <div class="stars">
            <img
              v-for="item of 5"
              class="star"
              src="../assets/star.png"
              :key="item"
            />
          </div> -->
      </div>
      <div class="bottom-btn">
        <div class="btn download" @click="handleDownLoad">立即下载</div>
      </div>
    </div>
    <div class="mask" v-if="showDialog" @click="handleClickMask">
      <div class="card">
        <div class="card-p">
          <p>{{ toastBrowser1 }}</p>
          <p>{{ toastBrowser2 }}</p>
          <div class="stop-info-div">
            <p>{{ toastBrowser3 }}</p>
            <p style="color: #57bfc1">{{ toastBrowser4 }}</p>
            <p>{{ toastBrowser5 }}</p>
          </div>
        </div>
        <img src="../assets/browser.png" class="arrow" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,

      pageName: "0xSeek - 零熵 Web3如此简单",

      appName: "零熵",

      toastTop: "0xSeek - 零熵",
      toastMid: "Web3一站式服务平台，快速入圈",

      desc: "投研 · 空投 · 资讯 · 基金",

      postUrl: "/assets/peipai.png",

      wechat: false, //是否为微信内浏览器打开
      ios: false, // 默认安卓
      //scheme: 'mihome://', //scheme地址 如果已安装app直接打开，置空则不执行
      downloadMap: {
        android: "/0xSeek.apk",
        ios: "",
      },
      toastBrowser1: "哎呀 ~ 微信不让“下载” 😭",
      toastBrowser2: "别担心，点击右上角,",
      toastBrowser3: "选择",
      toastBrowser4: "“在浏览器中打开”",
      toastBrowser5: "就可以啦~",
    };
  },
  created() {
    this.setPlatform();
    this.isWechat();
    //this.hanleOpenApp()
  },
  methods: {
    handleCheckDownload() {
    },
    judgeClient() {
      let client = "";
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //判断iPhone|iPad|iPod|iOS
        client = "ios";
      } else if (/(Android)/i.test(navigator.userAgent)) {
        //判断Android
        client = "android";
      } else {
        client = "pc";
      }
      return client;
    },
    handleDownLoadType(item) {
      window.location.href = this.downloadMap[item];
    },
    handleDownLoad() {
      // 若是微信h5 则提示
      if (this.wechat) {
        this.showDialog = true;
        return;
      }

      if (this.ios) {
        // ios设备
        if (this.downloadMap["ios"]) {
          window.open(this.downloadMap["ios"]);
        }
      } else {
        // andriod设备
        window.location.href = this.downloadMap["android"];
      }
    },
    handleClickMask() {
      this.showDialog = !this.showDialog;
    },
    setPlatform() {
      let platform = this.judgeClient();

      if (platform === "ios") {
        this.ios = true;
      }
    },
    isWechat() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        this.wechat = true;
      } else {
        this.wechat = false;
      }
    },
    hanleOpenApp() {
      if (this.scheme) {
        window.location = this.scheme;
      }
    },
  },
};
</script>

<style>
.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  height: 100vh;
  width: 100vw;
  background: rgba(35, 35, 35, 0.73);
  overflow: hidden;
}

.stopdownload {
  margin-top: 8px;
  background: #fddd10 !important;
}

.top {
  font-size: 16px;
  position: sticky;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  height: 80px;

  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6dede;
}

.logo {
  /* border-radius: 50%; */
  border-radius: 10px;
  width: 40px;
  height: 40px;
  z-index: 2;
}

.appName {
  margin-left: 20px;
}


.btn {
  color: #131313;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fddd10;
}

.sub-title {
  font-size: medium;
}

.apple-down-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downloadn-tips {
  margin-top: 10px;
}

.other-download-text {
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  color: #000;

  .icon-question {
    height: 16px;
    margin-right: 4px;
    width: 16px;
  }
}

.top .btn {
  width: 100px;
  height: 40px;
  border-radius: 15px;
}

.top .download {
  cursor: pointer;
  margin-left: auto;
}

.stop-info-div {
  display: flex;
}

.common_green {
  color: #fddd10;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
}

.toast {
  color: #131313;
  margin-top: 40px;
  font-size: 24px;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desc {
  color: #707070;
  margin-top: 16px;
  font-size: 15px;
}

.other-desc {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
  color: #8181ab;
}

.main .btn {
  width: 150px;
  height: 40px;
  border-radius: 20px;
  /* background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.75) 0%,
    ##fddd10 100%
  ); */
  background-color: #fddd10;
}

.main .download {
  cursor: pointer;
  margin-top: 25px;
}

.apple-download {
  flex: 1;
  padding: 6px 0;
  display: flex;
  flex-direction: column;
}

.post {
  margin-top: 30px;
  width: 80%;
  border-radius: 36px;
}

.download-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bottom {
  box-sizing: border-box;
  padding: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  width: 100%;
  background: rgba(35, 35, 35, 0.83);
}

.bottom .logo {
  width: 60px;
  height: 60px;
}

.bottom-btn {
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.bottom .btn {
  height: 40px;
  width: 100px;
  border-radius: 15px;
}

.info {
  margin-top: 6px;
  margin-left: 15px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info-name {
  color: #fddd10;
  font-size: 18px;
}

.info-desc {
  margin-top: 6px;
  color: #ffffff;
}

.stars {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
}

.star {
  margin-right: 5px;
  width: 10px;
  height: 10px;
}

.card {
  color: #131313;
  font-size: 13px;
  float: right;
  margin-right: 20px;
  margin-top: 6px;
  box-sizing: border-box;
  padding: 10px 0 10px 20px;
  width: 290px;
  height: 80px;
  border-radius: 10px;
  background: #ffffff;
  display: flex;
}

.card-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.arrow {
  margin-left: auto;
  width: 70px;
  height: 70px;
}

.backup {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 8px;
  font-size: 12px;
  text-align: center;
}

.backup a {
  color: #ffffff;
  text-decoration: none;
}
.apple-more-link {
  text-decoration-color: black;
}
</style>
